import { createI18n } from 'vue-i18n'

import fr from '~/assets/locales/fr.json'
import de from '~/assets/locales/de.json'
import en from '~/assets/locales/en.json'
import it from '~/assets/locales/it.json'


export const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: localStorage.getItem('lang') || 'fr',
    messages: {
        fr: fr,
        it,
        en,
        de
    }
})

export default defineNuxtPlugin(({ vueApp }) => {
    vueApp.use(i18n)
})


