import { FieldTypes, FormField } from "~/utils/form/fields/FormField";
import Form, {FormChoice} from "~/utils/form/Form";
import { Validators } from "~/utils/form/Validators";
import { Survey } from "~/model/Survey";
import {SelectFormField} from "~/utils/form/fields/SelectFormField";
import {SurveyStatusChoices} from "~/model/SurveyStatus";
import dayjs from "dayjs";

export default function SurveyForm(survey?: Survey): Ref<Form> {
    const form = ref(new Form());

    const langChoices: Array<FormChoice> = [
        {
            value: "fr",
            label: "FR"
        },
        {
            value: "de",
            label: "DE"
        },
        {
            value: "it",
            label: "IT"
        },
        {
            value: "en",
            label: "EN"
        }
    ]

    form.value.controls.push(new SelectFormField('status', FieldTypes.text, 'label.status', survey?.status ?? 'draft', [Validators.required], FieldTypes.text, SurveyStatusChoices))
    form.value.controls.push(new FormField('title', FieldTypes.text, 'label.title', survey?.title ?? null, [Validators.required], FieldTypes.text, {}))
    form.value.controls.push(new SelectFormField('lang', FieldTypes.text, 'label.lang', survey?.lang ?? 'fr', [Validators.required], FieldTypes.text, langChoices, {}))
    form.value.controls.push(new FormField('company_name', FieldTypes.text, 'label.company_name', survey?.company_name ?? null, [Validators.required], FieldTypes.text, {}))
    form.value.controls.push(new FormField('description_pdf', FieldTypes.text, 'label.description_pdf', survey?.description_pdf ?? null, [Validators.required], FieldTypes.text, {}))
    form.value.controls.push(new FormField('description_web', FieldTypes.text, 'label.description_web', survey?.description_web ?? null, [Validators.required], FieldTypes.text, {}))
    form.value.controls.push(new FormField('privacy_policy', FieldTypes.text, 'label.privacy_policy', survey?.privacy_policy ?? null, [], FieldTypes.text, {}))
    form.value.controls.push(new FormField('contact', FieldTypes.text, 'label.contact', survey?.contact ?? null, [Validators.required], FieldTypes.text, {placeholder:"En cas de problème informatique pour répondre au questionnaire, vous pouvez contacter Monsieur Nguyen, responsable informatique chez Ismat Consulting au 026/321.52.01, du mardi au vendredi de 8h30 à 12h et de 13h30 à 17h ou lui envoyer un e-mail à o.nguyen@ismat.com. Pour toute autre question, Ismat Consulting répond volontiers du lundi au vendredi de 8h à 12h et de 13h30 à 17h30 au numéro indiqué. Pour toutes questions sur cette démarche de sondage, vous pouvez contacter x, qui est responsable de ce projet (x)."}))
    form.value.controls.push(new FormField('deadline', FieldTypes.date, 'label.deadline', dayjs(survey?.deadline).format('YYYY-MM-DD') ?? null, [Validators.required], FieldTypes.dateTime, {}))
    form.value.controls.push(new FormField('estimated_duration', FieldTypes.number, 'label.estimated_duration', survey?.estimated_duration ?? null, [Validators.required], FieldTypes.number, {suffix:{text:'minutes'}}))
    form.value.controls.push(new FormField('logo', FieldTypes.date, 'label.logo', survey?.logo ?? null, [], FieldTypes.text, {}))
    form.value.controls.push(new FormField('image', FieldTypes.date, 'label.image', survey?.image ?? null, [], FieldTypes.text, {}))

    return form;
}
